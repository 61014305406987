#main-menu-navigation .active {
  color: #7367f0;
  font-weight: bold; }

.vcenter {
  marginTop: 'auto';
  marginBottom: 'auto'; }

.react-datetime-picker {
  display: block !important; }
  .react-datetime-picker .react-datetime-picker__wrapper {
    border-radius: 5px;
    padding: 5px;
    border-color: #d8d6de; }

.react-datepicker__input-container input {
  height: 38px;
  borderRadius: 5px;
  border: 1px solid #d8d6de;
  width: 100%;
  padding-left: 5px; }

.react-datetimerange-picker__wrapper, .react-time-picker__wrapper {
  padding: 5px;
  border-radius: 3px;
  border-color: #d0d0d0 !important; }

.react-datetimerange-picker {
  width: 100% !important; }
  .react-datetimerange-picker .react-datetimerange-picker__inputGroup:first-child {
    flex-grow: 0; }

.close {
  transform: inherit !important;
  background: none !important; }

.online {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #65fb3f;
  background: radial-gradient(circle, #65fb3f 0%, #285b1c 100%); }

.offline {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #dedede;
  background: radial-gradient(circle, #dedede 0%, #414141 100%); }
