@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap");
* {
  font-family: 'Prompt', sans-serif !important; }

.navbar-nav a:link {
  font-weight: 500; }

.flex-center {
  display: flex;
  justify-content: center; }

.flex-vcenter {
  display: flex;
  justify-content: center;
  align-items: center; }

.cbtn {
  border-radius: 20px; }

.rounded {
  background: #fff;
  border-radius: 20px !important;
  padding: 10px;
  margin-bottom: 10px; }

.shadow {
  box-shadow: 1px 1px 5px #6c521a; }

strong {
  font-weight: 500; }

.navStatic {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1; }

.navStatic + #hero {
  margin-top: 67.81px; }
