@import url(https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap);
html, body, #root {
  height: 100%; }

.react-confirm-alert-body h1 {
  font-size: 1.5rem; }

.react-confirm-alert-overlay {
  z-index: 11000 !important; }

#main-menu-navigation .active {
  color: #7367f0;
  font-weight: bold; }

.vcenter {
  marginTop: 'auto';
  marginBottom: 'auto'; }

.react-datetime-picker {
  display: block !important; }
  .react-datetime-picker .react-datetime-picker__wrapper {
    border-radius: 5px;
    padding: 5px;
    border-color: #d8d6de; }

.react-datepicker__input-container input {
  height: 38px;
  borderRadius: 5px;
  border: 1px solid #d8d6de;
  width: 100%;
  padding-left: 5px; }

.react-datetimerange-picker__wrapper, .react-time-picker__wrapper {
  padding: 5px;
  border-radius: 3px;
  border-color: #d0d0d0 !important; }

.react-datetimerange-picker {
  width: 100% !important; }
  .react-datetimerange-picker .react-datetimerange-picker__inputGroup:first-child {
    flex-grow: 0; }

.close {
  transform: inherit !important;
  background: none !important; }

.online {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #65fb3f;
  background: radial-gradient(circle, #65fb3f 0%, #285b1c 100%); }

.offline {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #dedede;
  background: radial-gradient(circle, #dedede 0%, #414141 100%); }

* {
  font-family: 'Prompt', sans-serif !important; }

.navbar-nav a:link {
  font-weight: 500; }

.flex-center {
  display: flex;
  justify-content: center; }

.flex-vcenter {
  display: flex;
  justify-content: center;
  align-items: center; }

.cbtn {
  border-radius: 20px; }

.rounded {
  background: #fff;
  border-radius: 20px !important;
  padding: 10px;
  margin-bottom: 10px; }

.shadow {
  box-shadow: 1px 1px 5px #6c521a; }

strong {
  font-weight: 500; }

.navStatic {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1; }

.navStatic + #hero {
  margin-top: 67.81px; }

